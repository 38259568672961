{
    "home": "home",
    "article": "article",
    "picture": "picture",
    "note": "note",
    "about": "about",
    "ebook":" ebook",
    "create":" create",
    "update":" update",
    "loading": "loading"
}
